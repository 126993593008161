$assetsPath: '//cdn.switchfly.com/static/c4ca002/resources/shopping/images/themes/americanexpress';

// Imports and mixins
// -------------------------

// Required vars for imports/includes
$font-size-base: 16px;
$_box-shadow-full: 0 0 0 200vw rgba(0,0,0,.5);

@include two-line-tablehead;

@import "_theme-partials/_webfonts";
@import "_theme-partials/_brand-colors";
@import "_theme-partials/_typography";
@import "_theme-partials/_buttons";
@import "_theme-partials/_icons";
@import "_theme-partials/_forms";
@import "_theme-partials/_alerts";
@import "_theme-partials/_loaders";
@import "_theme-partials/_popovers";
@import "_theme-partials/_tabs";
@import "_theme-partials/_modals";
@import "_theme-partials/_pagination";
@import "_theme-partials/_datepicker";
@import "_theme-partials/_products";
@import "_theme-partials/_header-footer";

// Global Variables
// -------------------------
$aside-contents-section-background-color: #F7F8F9;
$aside-contents-section-top-header-background-color: $_primary_deep-blue;
$aside-contents-section-top-header-text-color: #fff;

$section-header-border-bottom-color: $_neutral_gray-03;
$checkout-aside-section-header-border-color: $section-header-border-bottom-color;
$checkout-form-container-padding: 0 0 0 24px; // 32px desired total - 8px on aside

$checkout-payment-option-icon__creditcard: url('#{$assetsPath}/payment_options.png');
$checkout-payment-option-icon__paypal: $checkout-payment-option-icon__creditcard;

$tooltip-bg: $_neutral_gray-05;

// Helper classes
// -------------------------
$or-text-color: $text-color;

// Section Padding
// -------------------------
$checkout-section-body-padding: 20px 0;
$hotel-detail-section-body-padding: $checkout-section-body-padding;

// Security Code Help Image Variant
// -------------------------
.sec-code {
  @include secCodeVariant(amex);
}

// Visibility Helpers
// -------------------------

@media (max-width:480px) {
  .hidden-phone {
    display: none;
  }
}

@media (max-width:769px) {
  .hidden-tablet-down {
    display: none;
  }

  .visible-tablet-block {
    display: block;
  }

  .visible-tablet-inline-block {
    display: inline-block;
  }
}

@media (min-width:769px) {
  .hidden-tablet-up {
    display: none !important;
  }
}

@media (min-width:480px) {
  .hidden-desktop {
    display: none !important;
  }
}

// hide the iNav header/footer and terms and conditions content on app loading and interstitial page
.app-loading,
.page-interstitial {
  #iNavNGI_Header,
  #iNavNGI_FooterMain,
  #iNav_DDA_Header,
  #iNav_DDA_Footer,
  #iNavNGI_FooterWrap,
  #iNav_header_wrap,
  #iNav_header,
  #iNav_footer,
  .nts-terms-and-conditions,
  .show-on-homepage,
  .axp-global-header__dls__module___2gPze,
  .axp-footer__dls__module___2gPze {
    display: none;
  }
}

.loyalty-program-logo {
  background-image: url('#{$assetsPath}/membership_rewards_logo.png');
  background-repeat: no-repeat;
  height: 18px;
  width: 308px;
}

.external-debit-account-program-logo {
  width: 140px;
  height: 50px;
  background: url("#{$assetsPath}/external_debit_account_program_logo.png") no-repeat;
}

.external-debit-account-points-payment-option {
  border-bottom: 1px solid $gray-lighter;
  padding-bottom: 20px;
}

.case-handheld {
  .external-debit-account-points-payment-option label .row > div:last-of-type {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .automation-changeRooms.btn-link {
    color: white;
  }

  .automation-modalTitle {
    white-space: unset;
  }
}

.fare-options-container .branded-fare-names {
  .automation-refundableChangeableIndicator {
    white-space: inherit;
    text-align: left;
  }
}

.frequent-flyer {
  margin: 0 0 20px 0 !important;
}

#dashbar-modify-search {
    @extend .btn-primary;
}

@media print {
  div[data-module-name="axp-global-header"] > header,
  div#adwrapper,
  [class*="axp-footer__footer__legalLinksItem"],
  .sub-header [class^="axp-travel-navigation"],
  div.LPMcontainer.LPMoverlay img,
  #lpChat {
    display: none !important;
  }

}

